/* You can add global styles to this file, and also import other style files */

body
    padding: 0px !important
    margin: 0px !important

.custom-dialog-container
    padding: 0px !important
    z-index: 10000 !important

.custom-modal-background
    background: red

.dialog-container
    z-index: 10000000 !important

.mat-dialog-container 
    padding: 0px !important
    z-index: 10000 !important

.pi-bars
    display: none !important

.custom-mat-dialog-overlay
    z-index: 10000000 !important

.exito
    background: #4DE574
    width: 100px
    z-index: 10000 !important

.error
    background: #F51D58
    z-index: 10000 !important

.timepicker-overlay
    z-index: 10000000 !important

.timepicker-backdrop-overlay
    z-index: 10000000 !important


.contacto-dialog
    width: 50% !important
    z-index: 10000 !important

.foto-dialog
    width: 50% !important
    z-index: 10000 !important

@media only screen and (max-width: 900px) 
    .contacto-dialog
        width: 80% !important

    .foto-dialog
        width: 80% !important

@media only screen and (max-width: 700px) 
    .contacto-dialog
        width: 85% !important

    .foto-dialog
        width: 85% !important

@media only screen and (max-width: 400px) 
    .contacto-dialog
        width: 95% !important

    .foto-dialog
        width: 100% !important